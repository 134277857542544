import {graphql} from "gatsby";
import React from "react";

import Layout from "../../components/Layout";
import BlogGallery from "../../createdpages/blog/BlogGallery/BlogGallery";

const loveforcraft = ({data}) => {
  return (
    <Layout seoData={{title: "Mason | Love for Craft"}}>
      <div className='full-bleed layouted bg-gray-200'>
        <div className='coll-header my-24'>
          <h1 style={{color: "#30489E"}}>Love for Craft</h1>
          <br />
          <h3 className='light w-full w-2/4'>
            From future of design to tips & advice on your store.
          </h3>
        </div>
        <BlogGallery data={data.allGhostPost.nodes} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allGhostPost(
      filter: {tags: {elemMatch: {slug: {in: ["design", "technology"]}}}}
    ) {
      nodes {
        feature_image
        localImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        featured
        id
        tags {
          slug
          name
        }
        title
        authors {
          profile_image
          name
        }
        reading_time
        slug
        html
        published_at
      }
    }
  }
`;

export default loveforcraft;
